import React from "react";
import "./Permission_Pogout_Modal.css";
import { Button, Modal } from "react-bootstrap";
import LogoutModal from "../LogoutModal/LogoutModal";

function Permission_Pogout_Modal(props) {
    const [modalShow, setModalShow] = React.useState(false);

    // const handleLogoutModal = async () => {
    //     setModalShow(true);
    //     props.onHide()
    //     // Automatically close the modal after 3 seconds
    //     setTimeout(() => {
    //         setModalShow(false);
    //     }, 3000);
    // };
    return (
        <>
            <div className=" Successfull_Modal_sec">
                <Modal
                    className="Permission_Pogout_Modal Successfull_Modal"
                    {...props}
                    size="sm"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered

                >
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <p>Do You Want To Logout?</p>
                        <div className="btn-holder">
                            <Button className="yes-btn" onClick={props.handleLogout}>Yes</Button>
                            <Button className="no-btn"  onClick={props.onHide}>No</Button>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>

            
        </>
    );
}

export default Permission_Pogout_Modal;
