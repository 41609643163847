import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "./api";

import { Per_Page_Dropdown, Select2Data, RegxExpression } from "./common";
import { getCart } from "./apis/addToCart/addToCart";

import { getWishlist } from "./apis/Wishlist/Wishlist";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://192.168.14.132:3030";
  // const IMG_URL = "http://192.168.14.116:3030";

  // const IMG_URL = "http://127.0.0.1:3030";
  const IMG_URL = "http://local-mandai-node.profcymabackend.com:3030";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  // const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [variant, setVariant] = useState("");
  const [userdata, setUserData] = useState({});
  const [isAllow, setisAllow] = useState([]);
  const [wishlistData, setWishlistData] = useState([]);
  const [addCartModal, setAddCartModal] = useState(false);
  const [shimmerLoader, setShimmerLoader] = useState(false);
  const minLength = 2;
  const maxLength = 30;

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const [cartCount, setCartCount] = useState(0);
  const [trigger, setTrigger] = useState(0)

  const triggerCartUpdate = () => {
    setTrigger(trigger === 0 ? 1 : 0)
  };

  const updateCartCount = async () => {
    if (signin) {
      try {
        const cartItems = await getData(`/cart/show`);
        setCartCount(cartItems?.data?.length || 0);
      } catch (error) {
        console.log(error);
      }
    }
    else if (Cookies.get("local_mandai_cart")) {
      setCartCount(JSON.parse(Cookies.get("local_mandai_cart")).length);
    }
  };

  useEffect(() => {
   

    updateCartCount();
  }, [Cookies.get("local_mandai_cart"), signin, trigger, addCartModal]);



  console.log("cartCountFrom COntext", cartCount);

  const isTokenValid = async () => {
    const token = Cookies.get("local_mandai_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("local_mandai_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        setisAllow([]);
        navigate("/");
      } else {
        setisAllow(decodedToken?.permissions);
        // getuserData(decodedToken.user);
        setUsertype(decodedToken.rolename);
        const cart = await getCart();
        await setCartCount(cart?.data?.length);
        const wishlist = await getWishlist();
        await setWishlistData(wishlist?.data);
        setSignin(true);
      }
    } else {
      setisAllow([]);
      setSignin(false);
      setUsertype("");
      // navigate("/");
    }
  };

  const wishlistContext = async () => {
    if (signin) {
      const res = await getWishlist();
      if (res?.success) {
        setWishlistData(res?.data);
        console.log("wishlistData,", wishlistData);
      }
    }else{
      setWishlistData([]);
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };


  // Togle
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };
  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,
        minLength,
        maxLength,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        getDownloadDataExcel,
        ErrorNotify,
        variant,
        setVariant,
        // token,
        // setToken,
        toggleSidebar,
        isSidebarOpen,
        setSidebarOpen,
        Select2Data,
        Per_Page_Dropdown,
        RegxExpression,
        shimmerLoader,
        setShimmerLoader,
        addCartModal,
        setAddCartModal,
        cartCount,
        triggerCartUpdate,
        wishlistData,
        setWishlistData,
        wishlistContext,
        updateCartCount,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
