import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import "./LogIn.css";
import Forget_Password from "./Forget_Password/Forget_Password";
import Register_Form from "./Register_Form/Register_Form"; // Import Register_Form component
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Common_Button from "../Common-Component/Common_Button/Common_Button";

import { Errors, Placeholders } from "../../utils/errors";
import { RegxExpression } from "../../utils/common";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import { Context } from "../../utils/context";
import Cookies from "js-cookie";

import { Login } from "../../utils/apis/login/Login";
import { loginCart } from "../../utils/apis/addToCart/addToCart";
import LoginModal from "../Common_modal/LoginModal/LoginModal"
function LogIn(props) {
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [showRegisterForm, setShowRegisterForm] = useState(false); // State variable for showing register form

  // Function to handle click event on "Create account?"
  const handleCreateAccountClick = () => {
    setShowRegisterForm(true);
  };

  const buttonStyle = {
    background: props.background,
    borderRadius: props.borderRadius,
    border: props.border,
    fontSize: props.fontSize,
    fontWeight: props.fontWeight,
    color: props.color,
    padding: props.padding,
  };


  const [modalShow, setModalShow] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [iconOne, setIconOne] = useState(faEyeSlash);

  const navigate = useNavigate();

  const togglePasswordVisibility = (field) => {
    if (field === "password") {
      setShowPassword(!showPassword);
      setIconOne(showPassword ? faEyeSlash : faEye);
    }
  };

  const {
    signin,
    setSignin, shimmerLoader,setShimmerLoader } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();


  const onSubmit = async (data) => {
    setShimmerLoader(true);
    const res = await Login(data);
    if (res?.success) {
      await Cookies.set('local_mandai_security', res?.data?.token);
      setSignin(true);
      await loginCart();

      setModalShow(true);
      setTimeout(() => {

        setModalShow(false);
        setShimmerLoader(false);
        navigate(-1);
      }, 3000);
    } else {
      setShimmerLoader(false);
      if (res?.message) {
        setError('email_or_contact', { message: res?.message });
        setError('password', "");
      }
    }
  }

  return (
    <>
      <div className="LogIn">
        {!showForgetPassword && !showRegisterForm && (
          <div className="row bg-color me-0 ms-0">
            {/* Login Form */}
            <div className="col-md-7 p-0 order-md-1 order-2">
              <div className="row justify-content-center me-0 ms-0  ">
                <div className="col-xl-7  col-md-8  col-sm-6 ">
                  <div className="back_to_span">
                    <Link to={"/"}>
                      <p>Back to Home</p>
                    </Link>
                  </div>

                  <div className="heading-holder">
                    <h4>Login</h4>
                  </div>

                  <div className="log_in_form">
                    <Form>
                      <Form.Group className="mb-3" controlId="">
                        <Form.Control
                          type="text"
                          name="email_or_contact"
                          placeholder="Email / Mobile Number"
                          {...register("email_or_contact", {
                            required: "Credentials required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email_or_contact,
                            "is-valid": getValues("email_or_contact"),
                          })}

                        />
                      </Form.Group>
                      {errors?.email_or_contact && (
                        <sup className="text-danger">{errors?.email_or_contact?.message}</sup>
                      )}

                      <Form.Group className="mb-3" controlId="">
                        <div className="password-field-holder">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password*"

                            name="password"
                            {...register("password", {
                              required: "Password is required"
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.password,
                              "is-valid": getValues("password"),
                            })}
                          />
                          <div className="eye-icon-holder ">
                            <FontAwesomeIcon
                              icon={iconOne}
                              className="eye-icon"
                              onClick={() => togglePasswordVisibility("password")}
                            />
                          </div>
                        </div>
                        <Link onClick={() => setShowForgetPassword(true)} >
                          <Form.Text
                            className="Forgot_password"
                          >
                          Forgot password?
                          </Form.Text>
                        </Link>
                      </Form.Group>

                      <div className="btn-holder">
                        <Common_Button className="SubmitBtn"  disable={shimmerLoader} text="Login" onClick={handleSubmit(onSubmit)} />

                      </div>

                      <div className="create_account mb-5">
                        {/* Attach click event handler */}
                        {/* <Link to={"/register-form"}> */}
                        <p onClick={handleCreateAccountClick}>
                          Create account ?
                        </p>
                        {/* </Link> */}
                      </div>
                    </Form>
                    <div className="log_in_with">
                      <p>or Login with social accounts</p>
                    </div>

                    <div className="social_img_holder">
                      <Link to="https://mail.google.com/">
                        <img
                          className="social_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Image/log_in/google.png"
                          }
                          alt="social_img"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Image Section */}
            <div className="col-md-5 p-0 order-md-2 order-1">
              <div className="img-section">
                <div className="img-holder">
                  <img
                    className="log_in_img"
                    src={
                      process.env.PUBLIC_URL + "/assets/Image/log_in/log_in.png"
                    }
                    alt="log_in_img"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {showForgetPassword && <Forget_Password />}
        {showRegisterForm && <Register_Form />}{" "}
        {/* Render Register_Form if showRegisterForm is true */}
      </div>
      <LoginModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
}

export default LogIn;
