import React, { useState, useEffect, useContext } from "react";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import PurchasedItemsReturnModal from "../../../Common_modal/Purchased_Items_Modal/PurchasedItemReturnModal";
import { orderGet, returnOrderGet } from "../../../../utils/apis/myAccount/myAccount";


const OrderReturnHistory = () => {


    const [modalShow, setModalShow] = React.useState(false);
    const [data, setData] = useState();

    // const [orders, setOrder] = useState([]);
    // const getOrders = async () => {
    //     const res = await orderGet();
    //     if (res?.success) {
    //         setOrder(res?.data);
    //     }
    // }

    // useEffect(() => {
    //     getOrders();
    // }, []);

    const [returnOrders, setReturnOrder] = useState([]);
    const getReturnOrders = async () => {
        const res = await returnOrderGet();
        if (res?.success) {
            setReturnOrder(res?.data);
        }
    }

    useEffect(() => {
        getReturnOrders();
    }, []);


    return (
        <>
            <section className="my-account checkout-inner-steps ">
                <div className="container">
                    <div className="account-table confirm_order Product_Cart">
                        <div className="heading_holder">
                            <Breadcrumb>
                                <Breadcrumb.Item >
                                    <Link to={"/"}>Home</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item >
                                    <Link to={"/my-account"}>My account</Link>
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active><Link to={"/order-history"}>Order history</Link></Breadcrumb.Item>
                            </Breadcrumb>
                        </div>

                        <div className="back-text-holder">
                            <Link to={"/my-account"}><p>Back</p></Link>
                        </div>

                        <div className="product_table">
                            <Table responsive bordered>
                                <thead>
                                    <tr>
                                        <th>S. No.</th>
                                        <th>Order No.</th>
                                        <th>Order Date</th>
                                        <th>Delivery Date</th>
                                        <th>Total</th>
                                        <th>Payment Type</th>
                                        <th>Payment Status</th>
                                        <th>Order Status</th>
                                        <th>Order Progress</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {returnOrders?.map((value, index) => (
                                        <>
                                            <tr>
                                                <td>{index+1}</td>
                                                <td>{value?.invoice_number}</td>
                                                <td>{(value?.createdAt).split('T')[0]}</td>
                                                <td>{value?.delivery_date?.split('T')?.[0]}</td>
                                                <td>₹ {value?.amount}</td>
                                                <td>{value?.order?.payment_mode}</td>
                                                <td className="green-text">{value?.order?.payment_status?.status === 1 ? 'Paid' : 'UnPaid'}</td>
                                                <td className="red-text">{value?.order_status?.order_status}</td>
                                                <td className="view-text" onClick={() => { setData(value); setModalShow(true); }}>View</td>
                                                
                                            </tr>


<tr> <td colspan={9}>No Order History Found</td></tr>

                                          
                                        </>
                                    ))}
                                    {/* <tr>
                                        <td>2</td>
                                        <td>#567865</td>
                                        <td>19-09-21</td>
                                        <td>21-09-21</td>

                                        <td>₹500</td>
                                        <td>-</td>
                                        <td>Online</td>
                                        <td className="red-text">Unpaid</td>
                                        <td className="yellow-text">Ordered</td>
                                        <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>#567865</td>
                                        <td>19-09-21</td>
                                        <td>21-09-21</td>

                                        <td>₹500</td>
                                        <td>-</td>
                                        <td>Online</td>
                                        <td className="red-text">Unpaid</td>
                                        <td className="cancel-text">Canceled</td>
                                        <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                                    </tr> */}
                                </tbody>
                            </Table>

                            <div className="span-holder">
                                {/* <span>Showing 3 of 3 entries</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PurchasedItemsReturnModal
                data={data}
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </>
    )
}

export default OrderReturnHistory