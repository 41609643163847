import React, { useState, useEffect, useContext } from "react";
import "./ContactUs.css";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Common_Button from "../Common-Component/Common_Button/Common_Button";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { postContactUs } from "../../utils/apis/common/Common";

import Successfull_Modal from "../Common_modal/Successfull_Modal/Successfull_Modal";

const ContactUs = () => {
  const [showForm, setShowForm] = useState(false);
  const [showBg, setShowBg] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [showContactTextHolder, setShowContactTextHolder] = useState(true);

  const handleMapTextClick = () => {
    setShowForm(true);
    setShowBg(true);
    setShowContactTextHolder(false);
  };

  const handleArrowClick = () => {
    setShowContactTextHolder(true);
    setShowForm(false);
    setShowBg(false);
  };

  const { getData, IMG_URL, Select2Data } = useContext(Context);
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();


  const onSubmit = async (data) => {
    const res = await postContactUs(data)
    if (res?.success) {
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        reset();
      }, 3000);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  return (
    <>
      <section className="contact-us">
        <div className="map-section">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe
                className="gmap_iframe"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                src="https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
              ></iframe>
              <a href="https://pdflist.com/" alt="pdflist.com"></a>
            </div>
          </div>
        </div>
        {/* <div className="contact_form_holder "> */}
        <div
          className={`contact_form_holder ${showBg ? "contact_form_bg" : ""}`}
        >
          <div className="container">
            <div className="row justify-content-end">
              <div className="col-lg-4 col-md-4">
                <div
                  className={`contact-text-holder ${showContactTextHolder ? "" : "d-none"
                    }`}
                >
                  <div className="heading-holder mb-4">
                    <h2>Contact us</h2>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-phone"
                      />
                    </div>
                    <div className="address-holder">
                      <p>01123968786</p>
                    </div>
                  </div>
                  <div className="d-flex mb-3">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-envelope"
                      />
                    </div>
                    <div className="address-holder">
                      <p>support@ninjakraft.com</p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                    <div className="address-holder">
                      <p>
                        {" "}
                        516/4, Gali Rajan Kalan, Kashmere <br /> Gate Delhi,
                        Delhi, 110006{" "}
                      </p>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="icon-holder me-3">
                      <FontAwesomeIcon
                        className="call-icon"
                        icon="fa-solid fa-map-location-dot"
                      />
                    </div>
                    <div
                      className="address-holder map-text"
                      onClick={handleMapTextClick}
                    >
                      <p>Find Us on Map</p>
                    </div>
                  </div>

                  <div className="border-holder  my-4"></div>
                  <div className="social-icon">
                  <Link to={"https://www.facebook.com"} target="blank"> <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/facebook.png"
                      }
                      className="facebook-icon me-2"
                    /></Link>
                     <Link to={"https://in.linkedin.com/"} target="blank">  <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/linkedin.png"
                      }
                      className="facebook-icon me-2"
                    /></Link>
                   <Link to={"https://in.pinterest.com/"} target="blank"> <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/pinterest.png"
                      }
                      className="facebook-icon me-2"
                    /></Link>
                     <Link to={"https://x.com/"} target="blank">  <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/contact/twitter.png"
                      }
                      className="facebook-icon"
                    />
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className={`${showForm
                  ? "col-xl-5 col-lg-7 col-12 scale-down-hor-right"
                  : "col-lg-8 col-md-8 scale-up-hor-right"
                  }`}
              >
                <div className="form-holder ">
                  <div
                    className={`arrow-holder ${showForm ? "arrow-holder" : "d-none"
                      }`}
                    onClick={handleArrowClick}
                  >
                    {/* <FontAwesomeIcon className="left-arrow" icon="fa-solid fa-arrow-left" /> */}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Image/Icon/arrow-left.png"
                      }
                      className="left-arrow"
                    />
                  </div>
                  <Form>
                    <Row className="form_container">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.name && <div className="invalid-feedback">{errors.name.message}</div>}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1 "
                      >

                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="E-mail Address"
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}(?:\.[A-Za-z]{2,})?$/,
                              message: "Email Id Invalid",
                            },
                          })}
                          className={classNames("", { "is-invalid": errors?.email })}
                        />
                        {errors.email && <div className="invalid-feedback">{errors.email.message}</div>}
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Control
                          type="text"
                          name="subject"
                          placeholder="Subject"
                          {...register("subject", {
                            required: "subject is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.subject,
                          })}
                        />
                        {errors.subject && <div className="invalid-feedback">{errors.subject.message}</div>}
                      </Form.Group>

                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Control
                          as="textarea"
                          name="message"
                          type="text"
                          rows={3}
                          placeholder="Enter Message"
                          {...register("message", {
                            required: "Message required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.message,
                          })}
                        />
                        {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                      </Form.Group>
                      <div className="btn-holder">
                        <Common_Button
                          className="SubmitBtn"
                          text="Send Message"
                          padding="7px 20px"
                          onClick={handleSubmit(onSubmit)}
                        />
                      </div>
                    </Row>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Successfull_Modal show={successModal} onHide={() => setSuccessModal(false)} message={"Inquiry Sent Successfully"} />
    </>
  );
};

export default ContactUs;
