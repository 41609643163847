import React, { useState, useEffect, useContext } from "react";
import "../Product_Cart.css";
import "../Shop_Now/Shop_Now.css";
import { Breadcrumb, Button, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Cookies from 'js-cookie';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import CoupanModal from "../../Common_modal/coupon_modal/couponModal"
import CouponModal from "../../Common_modal/CouponModal/CouponModal"


import { Context } from "../../../utils/context";

import { CartQuantity, getCart } from "../../../utils/apis/addToCart/addToCart";

import { getCouponCode } from "../../../utils/apis/orderProcess/orderProcess";
import { Errors } from "../../../utils/errors";


const Cart = ({ type, amount, setAmount, discount, setDiscount, coupon, setCoupon, setTotalTax, tax }) => {

    const navigate = useNavigate();
    const { IMG_URL, signin, triggerCartUpdate } = useContext(Context);



    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        trigger,
    } = useForm();

    var [totalPrice, setTotalPrice] = useState(0);
    var [totalTax, setTotalTax] = useState(0);
    var [grandTotal, setGrandTotal] = useState(0);
    var [message, setMessage] = useState("");
    const [modalShow, setModalShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [couponStatus, setCouponStatus] = useState(false);


    const onSubmit = async (data) => {
        setError('code', "");
        console.log(data);
        const res = await getCouponCode(data?.code, amount);
        if (res?.success) {


            console.log(Number(totalPrice) * Number(res?.couponCode?.discount) / 100, 'total');
            if (res?.couponCode) {
                setModalShow(true);
                setCoupon(res?.couponCode?.id);
                setCouponStatus(true);

                if (res?.couponCode?.type === "percentage") {
                    // console.log(total);
                    setDiscount(Number(totalPrice) * Number(res?.couponCode?.discount) / 100);
                    setMessage(`${res?.couponCode?.discount} % Discount Applied`);

                } else {
                    setDiscount(res?.couponCode?.total_amount);
                    setMessage(`Coupen Applied of Rs. ${res?.couponCode?.total_amount}`);
                }
                setTimeout(() => {
                    setModalShow(false);
                    setMessage("");
                }, 3000);
            } else {
                setDiscount(0);
                setCoupon("");
                setCouponStatus(false);
                setError('code', { message: "Coupen Invalid" });

            }
        } else {
            setDiscount(0);
            setCoupon("");
            setCouponStatus(false);
            setError('code', {
                message: res?.message || 'Invalid Coupen'
            });
            console.log("error :-", res?.message);
        }

    }
    // console.log("error", errors?.code?.message);

    const handleOpenModal = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const [cart, setCart] = useState([]);
    const getCartQuantity = async (id, type) => {

        setDiscount(0);
        setCoupon();
        setCouponStatus(false);
        setError('code', "");

        await CartQuantity(id, type);
        if (Cookies.get('local_mandai_cart')) {
            if (JSON.parse(Cookies.get('local_mandai_cart')).length === 0) {
                navigate('/shop-now');
            }
            setCart(JSON.parse(Cookies.get('local_mandai_cart')));
            withoutCart();
        }
        if (signin) {
            await cartGet();
        }
        triggerCartUpdate()
    }
    console.log("cart", cart);

    const cartGet = async () => {
        const res = await getCart();
        if (res?.success) {
            console.log(res);
            if (res?.data.length === 0) {
                navigate('/shop-now');
            }
            setCart(res?.data);
        }

    }

    const withoutCart = async () => {
        if (Cookies.get('local_mandai_cart')) {
            if (JSON.parse(Cookies.get('local_mandai_cart')).length === 0) {
                navigate('/shop-now');
            }
            await setCart(JSON.parse(Cookies.get('local_mandai_cart')));
        }
    }

    useEffect(() => {
        if (signin) {
            cartGet();
        } else {
            withoutCart();
        }
    }, [Cookies.get('local_mandai_cart'), signin]);

    useEffect(() => {
        getCartQuantity();
    }, []);

    const handleNavigate = async (product_id) => {
        localStorage.setItem('product_id', product_id);
        navigate('/vendor-page');
    }

    return (
        <>

            {cart.length > 0 ? (
                <>
                    <div className="row">
                        <div className="col-xxl-9 col-xl-8 col-lg-7 col-md-12">
                            <div className="product_table">
                                {signin ? (

                                    cart?.map((value, index) => {
                                        totalPrice += value?.product_variant?.s_price * value?.quantity;
                                        totalTax += (value?.product?.product_detail?.tax_percentage?.name * (value?.product_variant?.s_price * value?.quantity)) / 100;
                                        return (
                                            <div className="row tabless mb-4">
                                                <div className="col-xxl-2 col-xl-2  col-lg-3 col-md-3 col-sm-3 col-4">

                                                    <div className="cabbageimg" onClick={() => handleNavigate(value?.product_id)}>
                                                        <img
                                                            src={
                                                                IMG_URL +
                                                                value?.product_variant?.product_images[0]?.image
                                                            }
                                                            className="cartimg"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-9 col-sm-9 col-8">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="prices">
                                                                <p className="cabbage " >{value?.product?.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                                                            <div className="prices">
                                                                <p className="prce me-2">
                                                                    Price : <span>₹{value?.product_variant?.s_price}</span>
                                                                </p>
                                                                {/* <p className="prce me-2">
                                                                Tax : <span>{value?.product?.product_detail?.tax_percentage?.name}%</span>
                                                            </p> */}
                                                                <p className="prce">
                                                                    Tax : <span>₹{(value?.product?.product_detail?.tax_percentage?.name * (value?.product_variant?.s_price * value?.quantity)) / 100}</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12 ">
                                                            {/* <div className="prices">
                                                        
                                                            <p className="prce">
                                                                <span></span>
                                                            </p>
                                                            <p className="prce">
                                                                Tax : <span>₹{(value?.product?.product_detail?.tax_percentage?.name * (value?.product_variant?.s_price * value?.quantity)) / 100}</span>
                                                            </p>
                                                        </div> */}
                                                        </div>
                                                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-12">
                                                            <div className="prices">
                                                                {/* <p className="cabbage cabbage2">{value?.product?.name}</p> */}
                                                                <p className="prce">
                                                                    Total Price: <span>₹{((value?.product_variant?.s_price * value?.quantity) +
                                                                        ((value?.product?.product_detail?.tax_percentage?.name * (value?.product_variant?.s_price * value?.quantity)) / 100)).toFixed(2)}</span>
                                                                </p>
                                                                <div className="qnty">
                                                                    <p className="prce mb-0">Quantity:</p>
                                                                    <div className="counting">
                                                                        <button
                                                                            className="count-btn"
                                                                            type="button"
                                                                            onClick={async () => {
                                                                                await getCartQuantity(value?.id, 'minus');
                                                                                await cartGet();
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon="fa-solid fa-minus"
                                                                                className="minnusicns"
                                                                            />
                                                                        </button>
                                                                        <p className=" prce nombers">{value?.quantity}</p>
                                                                        <button
                                                                            className="count-btn"
                                                                            type="button"
                                                                            onClick={async () => {
                                                                                await getCartQuantity(value?.id, 'add');
                                                                                await cartGet();
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon
                                                                                icon="fa-solid fa-plus"
                                                                                className="plusicns"
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    cart?.map((value, index) => {
                                        totalPrice += value?.price * value?.quantity;
                                        totalTax += (value?.tax_percent * (value?.price * value?.quantity)) / 100;
                                        return (
                                            <div className="row tabless mb-4">
                                                <div className=" col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-2">
                                                    <div className="cabbageimg" onClick={() => handleNavigate(value?.product_id)}>

                                                        <img
                                                            src={
                                                                IMG_URL +
                                                                value?.image
                                                            }
                                                            className="cartimg"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-4">
                                                    <div className="prices">
                                                        <p className="cabbage">{value?.name}</p>
                                                        <p className="prce me-2">
                                                            Price : <span>₹{(value?.price)}</span>
                                                        </p>
                                                        {/* <p className="prce me-2">
                                                        Tax : <span>{value?.tax_percent}%</span>
                                                    </p> */}
                                                        <p className="prce">
                                                            Tax : <span>₹{(value?.tax_percent * (value?.price * value?.quantity)) / 100}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5 me-5">
                                                    <div className="prices">
                                                        <p className="cabbage cabbage2">{value?.name}</p>
                                                        <p className="prce">
                                                            {/* Order Price: <span>₹30</span> */}
                                                        </p>

                                                    </div>
                                                </div>
                                                <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-4 col-5">
                                                    <div className="prices">
                                                        <p className="cabbage cabbage2">{value?.name}</p>
                                                        <p className="prce">
                                                            Total Price: <span>₹{((value?.price * value?.quantity) + ((value?.tax_percent * (value?.price * value?.quantity)) / 100)).toFixed(2)}</span>
                                                        </p>
                                                        <div className="qnty">
                                                            <p className="prce mb-0">Quantity:</p>
                                                            <div className="counting">
                                                                <button
                                                                    className="count-btn"
                                                                    type="button"
                                                                    onClick={() => getCartQuantity(value?.id, 'minus')}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon="fa-solid fa-minus"
                                                                        className="minnusicns"
                                                                    />
                                                                </button>
                                                                <p className=" prce nombers">{value?.quantity}</p>
                                                                <button
                                                                    className="count-btn"
                                                                    type="button"
                                                                    onClick={() => getCartQuantity(value?.id, 'add')}
                                                                >
                                                                    <FontAwesomeIcon
                                                                        icon="fa-solid fa-plus"
                                                                        className="plusicns"
                                                                    />
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                )}
                            </div>
                            <Link to={"/"}>
                                <div className="Continue_Shopping_text">
                                    <p>Continue Shopping</p>
                                </div>
                            </Link>
                        </div>

                        <div className="col-xxl-3 col-xl-4 col-lg-5 col-md-12">
                            <div className="grand_total_table">
                                <div className="orderss">
                                    <h6 className="ordersum">Order Summary</h6>
                                </div>
                                <Table responsive>
                                    <thead>
                                        <tr className="top-border">
                                            <th>Total amount</th>
                                            <td>₹{totalPrice}</td>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr className="top-border">
                                            <th>Discount</th>
                                            <td>₹ {discount}</td>
                                        </tr>
                                    </thead>

                                    <thead>
                                        <tr className="top-border">
                                            <th>Tax</th>
                                            <td>₹{totalTax.toFixed(2)}</td>
                                        </tr>
                                    </thead>
                                    <thead>
                                        <tr>
                                            <th>Shipping</th>
                                            <td>₹0</td>
                                        </tr>
                                    </thead>

                                    {type === 'checkout' && (
                                        <thead>
                                            <tr className="Coupon_code_holder top-border">
                                                <th className="Coupon_code">
                                                    <Form>

                                                        <Form.Control
                                                            type="text"
                                                            name="code"
                                                            placeholder="Enter Code"
                                                            {...register("code", {
                                                                required: "Code is required",
                                                            })}
                                                            className={classNames("", {
                                                                "is-invalid": errors?.code,
                                                                "is-valid": getValues("code"),
                                                            })}
                                                            onChange={() => {
                                                                setDiscount(0);
                                                                setCoupon("");
                                                                setCouponStatus(false);
                                                                setError('code', "");
                                                            }}
                                                        />

                                                    </Form>

                                                </th>

                                                <td>
                                                    <div className="button-holder">
                                                        {couponStatus ? (
                                                         
                                                            <Button className="Checkout_btn" type="button" onClick={() => {
                                                                setDiscount(0);
                                                                setCoupon("");
                                                                setCouponStatus(false);
                                                                reset();
                                                            }}>
                                                                Remove Coupon
                                                            </Button>
                                                            
                                                        ) : (
                                                            <div>
                                                                <Button
                                                                    className="Checkout_btn"
                                                                    type="button"
                                                                    onClick={handleSubmit(onSubmit)}
                                                                >
                                                                    Apply Coupon
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>

                                            <tr>

                                                <div>

                                                <Button
                                                        className="Checkout_btn show-coupon-btn"
                                                        type="button"
                                                        onClick={handleOpenModal}
                                                    >
                                                        Show Coupons
                                                    </Button>
                                                    <CoupanModal show={showModal} handleClose={handleCloseModal} setValue={setValue}/>
                                                </div>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <sup className="text-danger" style={{ fontSize: '1.2em' }}>{errors?.code?.message}</sup>
                                                </td>
                                            </tr>
                                        </thead>
                                    )}
                                    <CouponModal show={modalShow} onHide={() => setModalShow(false)} message={message} />

                                    <thead>
                                        <tr className="bottom-border">
                                            <th className="bold-text">Total</th>
                                            <td className="bold-text">₹<span>{((totalPrice - discount) + totalTax).toFixed(2)}{setAmount((totalPrice - discount) + totalTax)}</span></td>
                                        </tr>
                                    </thead>
                                </Table>
                            </div>

                        </div>
                    </div >
                    {type !== 'checkout' && (
                    <div className="row justify-content-end">
                        <div className="col-xxl-3 col-xl-3 col-lg-4">
                            <div className="button-holder text-end">
                                {/* {signin && */}
                                <Link to={signin ? "/product-cart-details" : "/login"}>
                                    <Button className="procheckout_btn" type="button">
                                        Proceed to Checkout
                                    </Button>
                                </Link>
                                {/* } */}
                            </div>
                        </div>
                    </div>
                    )}

                </>
            ) : (
                <section className="Empty_Cart">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
                                <div className="shop-now-content">
                                    <div className="img-holder">
                                        <img
                                            src={
                                                process.env.PUBLIC_URL +
                                                "/assets/Image/Cash_On_Delivery_Process/basket.png"
                                            }
                                            className="basket_img"
                                        />
                                    </div>
                                    <div className="heading_holder mt-3">
                                        <h5>Your cart is Empty</h5>
                                        <p>
                                            You have no items in your shopping cart. Let's go buy
                                            something
                                        </p>
                                        <div className="main-btn-div">
                                            <Link to={"/"}>
                                                <button className="back-btn" type="button">Shop Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            )}
            {/* <CoupanModal
                show={showModal}
                handleClose={handleCloseModal}
            /> */}

        </>
    );

}

export default Cart;
