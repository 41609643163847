import React, { useState, useEffect, useContext, useRef } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import Cookies from "js-cookie";
import { Context } from "../../utils/context";
import { Category } from "../../utils/apis/Product/Product";
import { productList } from "../../utils/apis/Product/Product";
import { getCart } from "../../utils/apis/addToCart/addToCart";
import { Webheader, AppSetup } from "../../utils/apis/common/Common";
import { ListGroup } from "react-bootstrap";
import LogoutModal from "../Common_modal/LogoutModal/LogoutModal";
import Permission_Pogout_Modal from "../Common_modal/Permission_Pogout_Modal/Permission_Pogout_Modal";

library.add(fas);
const Header = () => {
  const location = useLocation();
  // const navigate = useNavigate();
  const [activeItem, setActiveItem] = useState(() => {
    return localStorage.getItem("activeItem") || "home";
  });

  useEffect(() => {
    const path = location.pathname.substr(1);
    updateActiveItem(path || "home");
  }, [location]);

  const updateActiveItem = (newActiveItem) => {
    localStorage.setItem("activeItem", newActiveItem);
    setActiveItem(newActiveItem);
  };
  const [activeLink, setActiveLink] = useState("home");
  useEffect(() => {
    getSetup();
    getHeader();
    getCategory();
    const path = location.pathname.substring(1);
    setActiveLink(path || "home");
  }, [location.pathname]);

  const handleNavItemClick = (id) => {
    navigate(`/select-from-category/${id}`);
    // setActiveLink(selectedLink);
    // const navbarToggle = document.querySelector(".navbar-toggler");
    // if (navbarToggle && window.innerWidth <= 992) {
    //   navbarToggle.click();
    // }
  };



  const navigateToCategory = (id) => {
    console.log(id, "adssasaddasadsads");
    navigate(`/select-from-category/${id}`);
  };

  const [searchToggled, setIsToggled] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsToggled(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleClick = () => {
    setIsToggled((prevState) => !prevState); // Toggle the state based on the previous state
  };

  const {
    cartCount,
    signin,
    setSignin,
    wishlistData,
    addCartModal,
    getData,
    updateCartCount,
    wishlistContext,
  } = useContext(Context);


  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category();
    if (res?.success) {
      setCategory(res.data);
    }
  };
  const [search, setSearch] = useState("");
  const [products, setProducts] = useState([]);
  const [product_id, setProductId] = useState([]);

  const getProducts = async () => {
    const res = await getData(`/without-login/product?term=${search}`);
    if (res?.success) {
      setProducts(res?.data);
    }
  };

  useEffect(() => {
    getProducts();
  }, [search]);

  const [header, setHeader] = useState([]);
  const getHeader = async () => {
    const res = await Webheader();
    if (res?.success) {
      setHeader(res?.data);
    }
  };
  const { IMG_URL } = useContext(Context);

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
    }
  };
  const [modalShow, setModalShow] = useState(false);
  const [successLogout, setSuccessLogout] = useState(false);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await Cookies.remove("local_mandai_security", { path: "/" });
    await setSignin(false);
    // Show the modal
    await updateCartCount();
    setModalShow(false);
    setSuccessLogout(true);
    setTimeout(() => {
      setSuccessLogout(false);
    }, 3000);
    await navigate("/");
  };

  const handleNavigate = async (product_id) => {
    localStorage.setItem('product_id', product_id);
    // navigate('/vendor-page');
    window.location.href = `/vendor-page`;
    setIsToggled(false);
  }

  // useEffect(() => {
  //   handleNavigate()
  // }, [product_id]);

  return (
    <>
      {/* start header section  */}
      <section
        className="second-header-sec"
        style={{
          backgroundColor: setups?.header_color,
        }}
      >
        <Container>
          <Navbar expand="lg">
            <Navbar.Brand as={Link} to="/">
              <img
                className="headlogo"
                src={IMG_URL + setups?.image}
                alt="Logo"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              {header?.length > 0 ? (
                <Nav className="mx-auto">
                  {header?.map((item, index) => (
                    item?.id === 7 ? (
                      <NavDropdown
                        title="All Categories"
                        id="basic-nav-dropdown"
                        className="me-3"
                        key={index}
                      >
                        {category?.map((value) => (
                          <NavDropdown.Item
                            key={value?.id}
                            onClick={() => navigateToCategory(value?.id)}
                          >
                            {value.name}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    ) : (
                      <Nav.Link
                        key={index}
                        as={Link}
                        to={item.url} // Assuming item.url contains the route path
                        active={activeItem === item.name} // Assuming item.name contains the name of the item
                        onClick={() => handleNavItemClick(item.name)} // Assuming handleNavItemClick accepts the item name as a parameter
                      >
                        {item.title}
                      </Nav.Link>
                    )
                  ))}
                  {/* <Nav.Link
                    as={Link}
                    to="/"
                    active={activeItem === "home"}
                    onClick={handleNavItemClick}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/near-me"
                    active={activeItem === "near-me"}
                    onClick={handleNavItemClick}
                  >
                    Near Me
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    active={activeItem === "about-us"}
                    onClick={handleNavItemClick}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    active={activeItem === "contact-us"}
                    onClick={handleNavItemClick}
                  >
                    Contact Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/blog"
                    active={activeItem === "blog"}
                    onClick={handleNavItemClick}
                  >
                    Blog
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/become-seller"
                    active={activeItem === "become-seller"}
                    onClick={handleNavItemClick}
                  >
                    Become a Seller
                  </Nav.Link>
                  <NavDropdown
                    title="All Categories"
                    id="basic-nav-dropdown"
                    className="me-3"
                  >
                    {category?.map((value) => {
                      return (
                        <NavDropdown.Item
                          key={value?.id}
                          onClick={() => navigateToCategory(value?.id)}
                        >
                          {value.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <Nav.Link
                    as={Link}
                    to="/faq"
                    active={activeItem === "faq"}
                    onClick={handleNavItemClick}
                  >
                    FAQs
                  </Nav.Link>  */}
                </Nav>
              ) : (
                <Nav className="mx-auto">
                  <Nav.Link
                    as={Link}
                    to="/"
                    active={activeItem === "home"}
                    onClick={handleNavItemClick}
                  >
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/near-me"
                    active={activeItem === "near-me"}
                    onClick={handleNavItemClick}
                  >
                    Near Me
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/about-us"
                    active={activeItem === "about-us"}
                    onClick={handleNavItemClick}
                  >
                    About Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/contact-us"
                    active={activeItem === "contact-us"}
                    onClick={handleNavItemClick}
                  >
                    Contact Us
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/blog"
                    active={activeItem === "blog"}
                    onClick={handleNavItemClick}
                  >
                    Blog
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    to="/become-seller"
                    active={activeItem === "become-seller"}
                    onClick={handleNavItemClick}
                  >
                    Become a Seller
                  </Nav.Link>
                  <NavDropdown
                    title="All Categories"
                    id="basic-nav-dropdown"
                    className="me-3"
                  >
                    {category?.map((value) => {
                      return (
                        <NavDropdown.Item
                          key={value?.id}
                          onClick={() => navigateToCategory(value?.id)}
                        >
                          {value.name}
                        </NavDropdown.Item>
                      );
                    })}
                  </NavDropdown>
                  <Nav.Link
                    as={Link}
                    to="/faq"
                    active={activeItem === "faq"}
                    onClick={handleNavItemClick}
                  >
                    FAQs
                  </Nav.Link>
                </Nav>
              )}

              <div className="all-icon ms-auto mt-lg-0 mt-md-3 mt-sm-3 mt-3">

                <FontAwesomeIcon
                  icon="fa-solid fa-magnifying-glass"
                  className="cart-logo"
                  onClick={toggleClick}
                />

                <Link className="cart-icon-holder" to={"/product-cart"}>

                  <FontAwesomeIcon
                    icon="fa-solid fa-cart-shopping"
                    className="cart-logo r"
                    onClick={handleNavItemClick}
                  />
                  <span className="count-span-holder">
                    {cartCount}
                  </span>
                </Link>

                {signin ? (
                  <>
                    {wishlistData?.length > 0 ? (
                      <Link to={"/wishlist"}>
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cart-logo"
                          onClick={handleNavItemClick}
                        />
                      </Link>
                    ) : (
                      <Link to={"/empty-wislist"}>
                        <FontAwesomeIcon
                          icon={faHeart}
                          className="cart-logo"
                          onClick={handleNavItemClick}
                        />
                      </Link>
                    )}

                    <Link to={"/my-account"}>
                      <FontAwesomeIcon
                        icon="fa-solid fa-user"
                        className="cart-logo"
                        onClick={handleNavItemClick}
                      />
                    </Link>
                    <Link className="login" onClick={() => setModalShow(true)}>
                      Logout
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to={"/empty-wislist"}>
                      <FontAwesomeIcon
                        icon={faHeart}
                        className="cart-logo "
                        onClick={handleNavItemClick}
                      />
                    </Link>
                    <Link to={"/login"} className=" login">
                      Login
                    </Link>
                  </>
                )}

                {searchToggled && (
                  <div ref={searchRef} className="input-group-box mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Search here"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);

                      }}
                    />
                    {/* </div>
                  <div className="product-dropdown"> */}
                    <ListGroup>
                      {products.map((product) => (
                        <ListGroup.Item
                          key={product?.value}
                          onClick={() => handleNavigate(product?.value)}
                        >
                          {product?.label}
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </div>

                )}

              </div>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </section>
      {/* end header section  */}

      <Permission_Pogout_Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        handleLogout={handleLogout}
      />

      <LogoutModal
        show={successLogout}
        onHide={() => setSuccessLogout(false)}
      />
    </>
  );
};

export default Header;
